(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name wbGdpr.service:PrivacyPreferences
   *
   * @description
   *
   */
  angular
    .module('wbGdpr')
    .service('PrivacyPreferences', PrivacyPreferences);

  function PrivacyPreferences($mdDialog, $document, defaultConsents, $window, _, $cookies) {
    var self = this
      , userConsentOptionsKey = 'userConsentOptions'
      , consents = defaultConsents.map(function (defaultConsent) {
      return _.find(JSON.parse(localStorage.getItem(userConsentOptionsKey)), {name: defaultConsent.name}) || defaultConsent;
    });

    self.userConsentOptionsKey = userConsentOptionsKey;
    self.hasUserSetConsentOptions = localStorage.getItem(userConsentOptionsKey) !== null;

    self.agree = function agree() {
      self.setConsents({
        userConsents: defaultConsents,
        reload: false
      });
    };

    self.show = function show() {
      return $mdDialog.show({
        parent: angular.element($document.body),
        controller: 'PrivacyPreferencesDialogCtrl',
        controllerAs: 'privacyPreferences',
        templateUrl: 'wb-gdpr/views/privacy-preferences-dialog.tpl.html',
        focusOnOpen: false,
        clickOutsideToClose: true
      })
        .then(function (userConsents) {
          self.setConsents({
            userConsents: userConsents,
            reload: true
          })
        });
    };

    self.checkUserConsentByFeature = function checkUserConsentByFeature(feature) {
      return _.every(_.filter(consents, {feature: feature}), {allowed: true});
    };

    self.getConsents = function getConsents() {
      return consents;
    };

    self.setConsents = function setConsents(options) {
      consents = options.userConsents;

      localStorage.setItem(userConsentOptionsKey, JSON.stringify(consents));

      if (_.get(options, 'reload', false)) {
        $window.location.reload();
      }
    };

    function removeUnallowedConsentData() {
      if (!self.checkUserConsentByFeature('Google Analytics')) {
        window['ga-disable-UA-69710730-1'] = true;
        window['ga-disable-UA-69710730-2'] = true;
      }

      _.forEach(_.filter(consents, {allowed: false, mandatory: false}), function (consent) {
        $cookies.remove(consent.name);
      });
    }

    removeUnallowedConsentData();
  }
}());
